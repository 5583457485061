import { Flex, Heading, Text } from '@sctex/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import Image from 'next/image'
import bunnyImage from '../../../../public/images/home/Object2 1.png'

const Home1 = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-hero"
        style={{ gap: '24px' }}
      >
        <Flex flex="1" flexDirection="column">
          <Heading scale="xl" color="#ffffff" mb="0px">
            {t('CTEX synergizes')}
          </Heading>
          <Heading scale="xl" color="#ffffff" mb="0px">
            {t('with MSMEs')}
          </Heading>
          <Text color="#ffffff" fontSize="18px" mb="24px">
            {t('The first Equity Crowdfunding platform built using CTEX smart chain technology')}
          </Text>
          <Flex>{!account && <ConnectWalletButton mr="8px" />}</Flex>
        </Flex>
        <Flex
          height={['auto', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
          alignSelf="center"
        >
          <Image src={bunnyImage} priority placeholder="blur" alt={t('Ctex swap')} />
        </Flex>
      </Flex>
    </>
  )
}

export default Home1
